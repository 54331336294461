import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { BellOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Button, Flex, Modal, Popover } from "antd";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { RootState } from "../../../store";
import { calculateWinner } from "../../../utils/commonFunctions";
import { FaTools } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { VscChromeMinimize } from "react-icons/vsc";
import { IoGameController } from "react-icons/io5";
import Typography from "antd/es/typography/Typography";
import DrawerCustom from "../../Drawer";
import GSTCalculator from "../GSTCalculator";
import Board from "../../game/Board";
import rightSideBarCss from "./style.module.scss";

function RightSideBar() {
  const { openItemList } = useSelector((state: RootState) => state.minimize);
  const { taskDrawerOpen, vendorDrawerOpen, contactDrawerOpen, accountItemDrawerOpen } = useSelector((state: RootState) => state.layout);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isGameOpen, setIsGameOpen] = useState(false);
  const draggleRef = useRef<HTMLDivElement>(null);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [amount, setAmount] = useState<number | null>(null);
  const [gstPercentage, setGstPercentage] = useState<number>(5);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const popoverContent = (
    <div>
      <Button
        type="link"
        onClick={showModal}
        style={{ display: "block", textAlign: "left", padding: 0, color: "black" }}
      >
        GST Calculator
      </Button>
      <Button type="link" style={{ display: "block", textAlign: "left", padding: 0, color: "black" }}>
        Standard Calculator
      </Button>
    </div>
  );

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setAmount(null);
    setGstPercentage(5);
  };

  const handleMinimize = () => {
    setIsModalOpen(false);
  };

  const [squares, setSquares] = useState<Array<string | null>>(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState<boolean>(true);
  const [winnerInfo, setWinnerInfo] = useState<{ winner: string | null; line: number[] | null }>(
    calculateWinner(squares)
  );
  const [mode, setMode] = useState<"friends" | "computer">("friends");
  const [difficulty, setDifficulty] = useState<"easy" | "medium" | "hard">("easy");

  const resetGame = (): void => {
    setSquares(Array(9).fill(null));
    setIsXNext(true);
    setWinnerInfo({ winner: null, line: null });
  };

  const handleGameClose = () => {
    setIsGameOpen(false);
    resetGame();
  };

  const classForDivider = `${rightSideBarCss.divider} ${openItemList.length > 0 ? rightSideBarCss.dividerLight : ""}`;
  const isSidebarLight = openItemList.length > 0 || taskDrawerOpen || vendorDrawerOpen || contactDrawerOpen || accountItemDrawerOpen;

  return (
    <div className={rightSideBarCss.sidebar}>
      <Flex
        align="center"
        className={`${rightSideBarCss.rightSidebar} ${isSidebarLight ? rightSideBarCss.light : ""}`}
        gap={15}
      >
        <Button type="text">
          <BellOutlined style={{ fontSize: 20 }} />
        </Button>
        <div className={classForDivider} />
        <Button type="text">
          <QuestionCircleOutlined style={{ fontSize: 20 }} />
        </Button>
        <Button type="text">
          <SearchOutlined style={{ fontSize: 20 }} />
        </Button>

        <div className={classForDivider} />

        <Popover content={popoverContent} trigger="click" placement="left">
          <Button type="text">
            <FaTools style={{ fontSize: 19 }} color="white" />
          </Button>
        </Popover>

        <div className={classForDivider} />

        <Button
          type="text"
          icon={<IoGameController color="white" size={19} />}
          onClick={() => setIsGameOpen(true)}
        ></Button>
        <div className={classForDivider} />

        <Avatar style={{ backgroundColor: "#C27D7D", color: "#fff" }}>OP</Avatar>
        <Avatar style={{ backgroundColor: "#5C9688", color: "#fff" }}>BM</Avatar>
      </Flex>

      <Modal
        title={
          <div
            style={{ width: "100%", cursor: "move" }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            <Flex align="center" justify="space-between">
              <Typography style={{ fontSize: "16px", fontWeight: "600" }}>GST CALCULATOR</Typography>
              <Flex gap={20}>
                <VscChromeMinimize onClick={handleMinimize} fontSize={18} style={{ cursor: "pointer" }} />
                <IoMdClose onClick={handleCancel} fontSize={18} style={{ cursor: "pointer" }} />
              </Flex>
            </Flex>
          </div>
        }
        open={isModalOpen}
        footer={false}
        // onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        zIndex={100000}
        mask={false}
        closable={false}
        // maskClosable={false}
        width={550}
      >
        <GSTCalculator
          amount={amount}
          setAmount={setAmount}
          gstPercentage={gstPercentage}
          setGstPercentage={setGstPercentage}
        />
      </Modal>

      <DrawerCustom
        handleMinimize={handleMinimize}
        drawerCloseHandle={handleGameClose}
        open={isGameOpen}
        width={820}
        closeText="Close"
        zIndex={1000}
      >
        <div
          style={{
            textAlign: "center",
            fontFamily: "'Arial', sans-serif",
            padding: "16px",
            backgroundColor: "#f9f9f9",
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ fontSize: "24px", marginBottom: "20px", color: "#333" }}>Tic Tac Toe</h1>

          <div style={{ marginBottom: "20px" }}>
            <button
              onClick={() => setMode("friends")}
              style={{
                padding: "10px 20px",
                marginRight: "10px",
                fontSize: "16px",
                backgroundColor: mode === "friends" ? "#4CAF50" : "#f0f0f0",
                color: mode === "friends" ? "#fff" : "#333",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "all 0.3s",
              }}
            >
              Play with Friend
            </button>
            <button
              onClick={() => setMode("computer")}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: mode === "computer" ? "#4CAF50" : "#f0f0f0",
                color: mode === "computer" ? "#fff" : "#333",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                transition: "all 0.3s",
              }}
            >
              Play with Computer
            </button>
          </div>

          {mode === "computer" && (
            <div style={{ marginBottom: "20px" }}>
              <label style={{ fontSize: "16px", color: "#333" }}>
                Select Difficulty:
                <select
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value as "easy" | "medium" | "hard")}
                  style={{
                    marginLeft: "10px",
                    padding: "5px 10px",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                  }}
                >
                  <option value="easy">Easy</option>
                  <option value="medium">Medium</option>
                  <option value="hard">Hard</option>
                </select>
              </label>
            </div>
          )}

          <Board
            mode={mode}
            difficulty={difficulty}
            squares={squares}
            setSquares={setSquares}
            isXNext={isXNext}
            setIsXNext={setIsXNext}
            winnerInfo={winnerInfo}
            setWinnerInfo={setWinnerInfo}
            resetGame={resetGame}
          />
        </div>
      </DrawerCustom>
    </div>
  );
}

export default RightSideBar;
