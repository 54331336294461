import React, { useEffect, useState } from "react";
import { Button, Flex, Form, Popover, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RiAttachment2 } from "react-icons/ri";
import { UploadChangeParam } from "antd/es/upload";
import styleCss from "./form.module.scss";
import { MdDeleteOutline, MdOutlineRemoveRedEye } from "react-icons/md";
import { AiOutlinePicture } from "react-icons/ai";

interface CommonUploadProps {
  fieldName: string;
  label?: string;
  onUploadChange: (fileList: any[]) => void;
  uploadedFiles: any[];
}

const UploadDoc: React.FC<CommonUploadProps> = ({ fieldName, label, onUploadChange, uploadedFiles }) => {
  const [fileList, setFileList] = useState<any[]>([]);
  useEffect(() => {
    setFileList(uploadedFiles);
  }, [uploadedFiles]);

  const handleUpload = (info: UploadChangeParam): void => {
    const newFiles = info?.fileList?.filter((file) => file?.status !== "removed" && file?.status !== "uploading");

    setFileList((prevFiles) => {
      const updatedFileList = [
        ...prevFiles,
        ...newFiles
          ?.filter((newFile) => !prevFiles.some((existingFile) => existingFile?.uid === newFile?.uid))
          ?.map((file) => ({
            uid: file?.uid,
            name: file?.name,
            url: URL.createObjectURL(file.originFileObj as Blob),
            originFileObj: file?.originFileObj,
          })),
      ];
      onUploadChange(updatedFileList);
      return updatedFileList;
    });
  };

  const handleFileRemove = (fileName: string): void => {
    const newFileList = fileList?.filter((file) => file?.name !== fileName);
    setFileList(newFileList);
    onUploadChange(newFileList);
  };

  const uploadedFilesContent = (
    <div className="fileList">
      {fileList.length > 0 &&
        fileList?.map((file) => (
          <Flex key={file?.name} align="center" gap={16}>
            <Flex align="center" gap={6}>
              <AiOutlinePicture size={20} color="#1677ff" />
              <span>{file?.name}</span>
            </Flex>
            <Flex align="center" className="actionBtn" gap={6}>
              <Button type="link" href={file?.url} target="_blank" style={{ padding: 0 }}>
                <MdOutlineRemoveRedEye color="#a3a3a3" size={18} />
              </Button>
              <Button type="link" onClick={() => handleFileRemove(file?.name)} style={{ padding: 0, color: "red" }}>
                <MdDeleteOutline color="#ff000375" size={18} />
              </Button>
            </Flex>
          </Flex>
        ))}
    </div>
  );

  return (
    <Flex align="center" gap={6} className={styleCss.fileUpload}>
      <Form.Item name={fieldName}>
        <Upload
          accept=".pdf,.jpg,.jpeg,.png"
          showUploadList={false}
          onChange={handleUpload}
          beforeUpload={() => false}
          multiple
        >
          <Button icon={<UploadOutlined />} className="uploadBtn">
            Upload
          </Button>
        </Upload>
      </Form.Item>

      {fileList?.length > 0 && (
        <Popover
          content={uploadedFilesContent}
          trigger="click"
          placement="bottomLeft"
          overlayClassName={styleCss.listPopover}
        >
          <Button color="primary" type="primary" disabled={fileList?.length === 0} className="files">
            <RiAttachment2 />
            {fileList?.length}
          </Button>
        </Popover>
      )}
    </Flex>
  );
};

export default UploadDoc;
