import Typography from "antd/es/typography/Typography";
import { Col, Flex, Row } from "antd";
import { LuInfo } from "react-icons/lu";
import styleCss from "../../style.module.scss";
import { InputBox, InputSelect } from "../../../../components/FormInput";
import { gstOption, gstRatOption, unitOption } from "../../../../utils/const";
import { capitalizeFirstLetterOfWord } from "../../../../utils/commonFunctions";
import { IoSearchOutline } from "react-icons/io5";

interface ItemFormPropsInterface {
  form: any;
  gstApplicable?: any;
  setGstApplicable?: any;
}

const ItemForm = ({ form, gstApplicable, setGstApplicable }: ItemFormPropsInterface) => {
  const handleGstApplicableChange = (val: string) => {
    setGstApplicable(val);
  };

  return (
    <div className={styleCss.FormComponent}>
      <div className="pt-5 pb-5 pl-6 pr-6">
        <Row gutter={[4, 8]} align={"middle"}>
          <Col span={7}>
            <Flex align="center" gap={6}>
              <Typography className="titleMendetory">Item Name</Typography>
              <LuInfo color="#a3a3a3" />
            </Flex>
          </Col>
          <Col span={17}>
            <InputBox
              className="input-field"
              name={"name"}
              placeholder="Enter Item name"
              rules={[{ required: true }]}
              onBlur={(e) => {
                form.setFieldValue("name", capitalizeFirstLetterOfWord(e.target.value));
              }}
            />
          </Col>

          <Col span={7}>
            <Typography className="titleMendetory">Unit</Typography>
          </Col>
          <Col span={17}>
            <InputSelect
              name={"unit"}
              options={unitOption}
              allowClear
              placeholder="Select a unit"
              style={{ width: "50%" }}
              rules={[{ required: true }]}
            />
          </Col>

          <Col span={7}>
            <Typography className="titleField">SAC</Typography>
          </Col>
          <Col span={17} className="itemSac">
            <Flex align="center" gap={6}>
              <InputBox className="input-field" name={"sac"} placeholder="Enter SAC" />
              <IoSearchOutline size={18} color="#1a73e8" />
            </Flex>
          </Col>

          <Col span={7}>
            <Flex align="center" gap={6}>
              <Typography className="titleMendetory">GST Applicability</Typography>
              <LuInfo color="#a3a3a3" />
            </Flex>
          </Col>
          <Col span={17}>
            <InputSelect
              name={"gst_applicable"}
              options={gstOption}
              allowClear
              placeholder="Select a Gst "
              style={{ width: "70%" }}
              handleChange={handleGstApplicableChange}
              rules={[{ required: true }]}
            />
          </Col>

          {gstApplicable !== "Non Applicable" && (
            <>
              <Col span={7}>
                <Flex align="center" gap={6}>
                  <Typography className="titleMendetory">GST Rate</Typography>
                  <LuInfo color="#a3a3a3" />
                </Flex>
              </Col>
              <Col span={17}>
                <InputSelect
                  name={"gst_rate"}
                  options={gstRatOption}
                  allowClear
                  placeholder="Select a Gst Rate"
                  style={{ width: "70%" }}
                  rules={[{ required: true }]}
                />
              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ItemForm;
