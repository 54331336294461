import React, { useEffect, useState } from "react";
import SearchHeader from "../../../components/SearchHeader";
import commonCss from "../../../css/commonStyle.module.scss";
import { Button, Table } from "antd";
import { setAccountItemDrawerOpen } from "../../../slices/layoutSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { DRAWER_TYPE } from "../../../utils/const";
import { useSelector } from "react-redux";
import DataTable from "../../../components/DataTable";
import { fetchItemList, IItems } from "../../../slices/Account/itemSlice";
import { openItem } from "../../../slices/minimizeSlice";
import CreateItem from "./CreateItem";

const Item = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { allItemList, loading } = useSelector((state: RootState) => state.accountItem);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    dispatch(fetchItemList());
  }, []);

  // useEffect(() => {
  //   if (vendorData) {
  //     setFieldValues(vendorData);
  //   }
  // }, [vendorData]);

  const column: any[] = [
    Table.SELECTION_COLUMN,
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
      onCell: (record: IItems) => {
        return {
          onClick: () => {
            itemOpenHandle(record);
          },
        };
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: "250px",
      onCell: (record: IItems) => {
        return {
          onClick: () => {
            itemOpenHandle(record);
          },
        };
      },
    },
    {
      title: "SAC",
      dataIndex: "sac",
      key: "sac",
      width: "300px",
      onCell: (record: IItems) => {
        return {
          onClick: () => {
            itemOpenHandle(record);
          },
        };
      },
    },
    {
      title: "GST Applicability",
      dataIndex: "gst_applicable",
      key: "gst_applicable",
      width: "300px",
      onCell: (record: IItems) => {
        return {
          onClick: () => {
            itemOpenHandle(record);
          },
        };
      },
    },
    {
      title: "GST Rate",
      dataIndex: "gst_rate",
      key: "gst_rate",
      width: "300px",
      render: (gst_rate: number | null | undefined) => (gst_rate ? `${gst_rate}%` : "-"),
      onCell: (record: IItems) => {
        return {
          onClick: () => {
            itemOpenHandle(record);
          },
        };
      },
    },
  ];

  const openCreateModel = () => {
    dispatch(setAccountItemDrawerOpen(DRAWER_TYPE.ACCOUNT_ITEM_DRAWER));
  };

  const itemOpenHandle = (value: IItems) => {
    dispatch(
      openItem({
        _id: value._id,
        name: value.name,
        type: "ITEM",
      })
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
    },
    getCheckboxProps: (record: any) => {},
  };

  const filteredList = allItemList.filter((item) => item.name.toLowerCase().includes(searchKeyword.toLowerCase()));

  return (
    <div>
      <SearchHeader
        title={"Item List"}
        searchValue={searchKeyword}
        onSearchChange={(e) => setSearchKeyword(e.target.value)}
      >
        <Button onClick={openCreateModel} className={commonCss.createBtn}>
          CREATE
        </Button>
      </SearchHeader>
      <CreateItem />
      <div style={{ marginTop: 15, cursor: "pointer" }}>
        <DataTable
          column={column}
          data={filteredList}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          loading={loading}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Item;
