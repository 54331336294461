import React from "react";
import { Form, Radio } from "antd";

export interface OptionsInterface {
  value: string;
  label: string;
}
export interface InputRadioPropsInterface {
  onChange?: (val: any) => void;
  options: OptionsInterface[];
  label?: string;
  name: any;
  className: string;
  defaultValue?: string;
}

const InputRadio: React.FC<InputRadioPropsInterface> = ({ defaultValue, onChange, options, ...rest }) => {
  return (
    <>
      <Form.Item {...rest} initialValue={defaultValue}>
        <Radio.Group onChange={onChange} defaultValue={defaultValue}>
          {options?.map((opt) => (
            <Radio value={opt?.value} key={opt?.label}>
              {opt?.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </>
  );
};

export default InputRadio;
