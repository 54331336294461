import { useEffect, useRef, useState } from "react";
import { Button, Divider, Flex, Form, message, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import ItemForm from "./ItemForm";
import { DRAWER_TYPE } from "../../../utils/const";
import { AppDispatch, RootState } from "../../../store";
import DrawerCustom from "../../../components/Drawer";
import { setAccountItemDrawerOpen } from "../../../slices/layoutSlice";
import { closeItem } from "../../../slices/minimizeSlice";
import _ from "lodash";
import {
  addItem,
  fetchItemList,
  IItems,
  resetAllFormState,
  setItemData,
  updateItemList,
} from "../../../slices/Account/itemSlice";
import accountStyle from "../style.module.scss";
import commonStyle from "../../../css/commonStyle.module.scss";

const { Text } = Typography;

interface ICreateItem {
  itemId?: string;
}

function CreateItem({ itemId }: ICreateItem) {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const { accountItemDrawerOpen } = useSelector((state: RootState) => state.layout);
  const { loading, ItemData, allItemList } = useSelector((state: RootState) => state.accountItem);
  const [isSaved, setIsSaved] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!itemId) return;
    const filteredItem = allItemList.find((item) => itemId === item._id);
    dispatch(setItemData(filteredItem));
  }, [itemId]);

  useEffect(() => {
    if (_.isEqual(accountItemDrawerOpen, DRAWER_TYPE.ACCOUNT_ITEM_DRAWER) || itemId) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [accountItemDrawerOpen, itemId]);

  useEffect(() => {
    if (ItemData) {
      setFieldValues(ItemData);
    }
  }, [ItemData]);

  const dataSaved = () => {
    setIsSaved(true);
    setTimeout(() => setIsSaved(false), 2000);
  };

  const drawerClose = () => {
    setIsModalOpen(false);
    form.resetFields();
    if (itemId) {
      setTimeout(() => {
        dispatch(closeItem(itemId));
        setIsSaved(false);
        dispatch(resetAllFormState());
      }, 300);
    } else {
      dispatch(resetAllFormState());
      form.resetFields();
      dispatch(setAccountItemDrawerOpen(null));
    }
  };

  const handleFinish = async (val: IItems) => {
    const itemData: any = {
      name: val?.name,
      unit: val?.unit,
      sac: val?.sac,
      gst_applicable: val?.gst_applicable,
      gst_rate: val?.gst_rate,
    };

    if (itemId && itemData) {
      const response: any = await dispatch(updateItemList({ id: itemId, updatedData: itemData }));
      if (response) {
        dataSaved();
      }
    } else {
      const response: any = await dispatch(addItem(itemData));
      if (response) {
        drawerClose();
        fetchItemList();
      } else {
        message.error(response.payload?.error?.message);
      }
    }
  };

  const handleFinishFailed = (errorInfo: any) => {
    if (errorInfo && errorInfo?.errorFields?.length > 0) {
      message.error("Please enter data in all required fields.");
    }
  };

  const setFieldValues = (item: any) => {
    const { _id } = item;

    if (_id) {
      form.setFieldValue("name", _.get(item, "name", ""));
      form.setFieldValue("gst_applicable", _.get(item, "gst_applicable", ""));
      const gstRate = _.get(item, "gst_rate", "");
      let formattedGstRate = "";
      if (gstRate === 5) {
        formattedGstRate = "5% (SGST 2.5% + CGST 2.5%)";
      } else if (gstRate === 18) {
        formattedGstRate = "18% (SGST 9% + CGST 9%)";
      } else {
        formattedGstRate = '';
      }
      form.setFieldValue("gst_rate", formattedGstRate);
      form.setFieldValue("sac", _.get(item, "sac", ""));
      form.setFieldValue("unit", _.get(item, "unit", ""));
    }
  };

  return (
    <>
      <DrawerCustom
        drawerCloseHandle={drawerClose}
        open={isModalOpen}
        width={"40%"}
        closeText="Item"
        showSavedStatus={itemId ? true : false}
        isLoading={loading}
        isDataUpdated={isSaved}
        showMinimize={itemId ? true : false}
        zIndex={1000}
        wrapClassName={accountStyle.accountDrawer}
      >
        <div>
          <div style={{ padding: "24px" }}>
            <Text className="drawer-title">{itemId ? "Item Detail" : "New Item"}</Text>
          </div>
        </div>

        <Divider
          style={{
            borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
            margin: 0,
          }}
        />

        <Form
          form={form}
          name="dynamic_form"
          autoComplete="off"
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          scrollToFirstError
        >
          <div className="vendorForm">
            <ItemForm form={form} />
            <Divider
              style={{
                borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
                margin: 0,
              }}
            />
            <Flex gap={15} justify="start" style={{ marginTop: "15px" }} className="pl-6">
              <Button className={commonStyle.primaryBtn} htmlType="submit" loading={loading}>
                Save
              </Button>
              <Button className={commonStyle.cancelBtn} onClick={drawerClose}>
                Cancel
              </Button>
            </Flex>
          </div>
        </Form>
      </DrawerCustom>
   </>
  );
}

export default CreateItem;
