import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import notesSlice from "./notesSlice";
import layoutSlice from "./layoutSlice";
import taskSlice from "./taskSlice";
import apiServiceSlice from "./apiServiceSlice";
import contactSlice from "./contactSlice";
import vendorSlice from "./vendorSlice";
import commonDataSlice from "./commonDataSlice";
import minimizeSlice from "./minimizeSlice";
import inquirySlice from "./inquirySlice";
import itemSlice from "./Account/itemSlice";

const rootReducer = combineReducers({
  user: userSlice,
  notes: notesSlice,
  layout: layoutSlice,
  task: taskSlice,
  inquiry: inquirySlice,
  apiService: apiServiceSlice,
  contact: contactSlice,
  vendor: vendorSlice,
  accountItem: itemSlice,
  commonData: commonDataSlice,
  minimize: minimizeSlice,
});

export default rootReducer;
