import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectRoute from "./security/ProtectRoute";
import { LocalStorageService } from "./utils/LocalStorage";
import { UserInterface } from "./slices/userSlice";
import Inquiry from "./pages/TaskAndInquiry";
import ContactList from "./pages/ContactList";
import UserLayout from "./components/UserLayout";
import Notes from "./pages/Notes";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import VendorList from "./pages/VendorList";
import { useSelector } from "react-redux";
import { RootState } from "./store";
import AccountList from "./pages/Account";

function AppRoutes() {
  const userData: UserInterface = LocalStorageService.getItem("user");
  const token = LocalStorageService.getItem("token");
  const { userConfig } = useSelector((state: RootState) => state.user);

  const routerPath = userData && token ? "/inquiry" : "/login";
  const routeList = [
    {
      path: "/login",
      component: Login,
      isAuthenticated: false,
    },
    {
      path: "/reset-password",
      component: ResetPassword,
      isAuthenticated: false,
    },
    {
      path: "/inquiry",
      component: Inquiry,
      layout: UserLayout,
      isAuthenticated: true,
    },
    {
      path: "/notes",
      component: Notes,
      layout: UserLayout,
      isAuthenticated: true,
    },
    {
      path: "/contacts",
      component: ContactList,
      layout: UserLayout,
      isAuthenticated: true,
    },
    {
      path: "/vendors",
      component: VendorList,
      layout: UserLayout,
      isAuthenticated: true,
    },
    {
      path: "/account/*",
      component: AccountList,
      layout: UserLayout,
      isAuthenticated: true,
    },
  ];

  useEffect(() => {
    if (userConfig?.theme?.themeUrl) {
      document.body.style.backgroundImage = `url(${userConfig?.theme?.themeUrl})`;
    }
  }, [userConfig?.theme?.themeUrl]);

  return (
    <Routes>
      {routeList.map((route) =>
        route.isAuthenticated ? (
          <Route
            key={route.path}
            path={route.path}
            element={<ProtectRoute component={route.component} layout={route.layout} />}
          />
        ) : (
          <Route key={route.path} path={route.path} Component={route.component} />
        )
      )}

      <Route path="/" element={<Navigate to={routerPath} />} />
    </Routes>
  );
}

export default AppRoutes;
