import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Divider, Form, Modal, Radio, Row, Typography, Button, Flex, message, RadioChangeEvent } from "antd";
import { InputBox, InputSelect } from "../../../components/FormInput";
import ContactInput from "../../../components/FormInput/contactInput";
import { QUICK_MODEL_TYPE } from "../../../utils/const";
import { AppDispatch, RootState } from "../../../store";
import { addReferenceList } from "../../../slices/contactSlice";
import { createPassengerQuickly, IQuickPassenger } from "../../../slices/taskSlice";
import styleCss from "../createDrawer.module.scss";
import { capitalizeFirstLetterOfWord } from "../../../utils/commonFunctions";

interface IQuickPassengerProp {
  isOpen: boolean;
  handleModelClose: () => void;
  onSave: (createPassId: string) => void;
  type: string;
}

const QuickPassengerModel = ({ isOpen, handleModelClose, onSave, type }: IQuickPassengerProp) => {
  const dispatch = useDispatch<AppDispatch>();
  const { referenceList } = useSelector((state: RootState) => state.contact);
  const [quickPassenger] = Form.useForm();
  const [passengerType, setPassengerType] = useState<string | null>("INDIVIDUAL");

  const onFinish = async (values: IQuickPassenger) => {
    const { passengerType, fullName, contactNumber, referenceBy, member } = values;
    const fromData = {
      contactType: passengerType ?? "INDIVIDUAL",
      fullName,
      contactNumber,
      referenceBy,
      mainPerson: member,
    };
    const response: any = await dispatch(createPassengerQuickly(fromData));
    if (!response?.error) {
      await dispatch(addReferenceList(response.payload));
      handleModelClose();
      quickPassenger.resetFields();
      setPassengerType("INDIVIDUAL");
      onSave(response?.payload?._id);
    } else {
      message.error(response.payload);
    }
  };

  const quickModalClose = () => {
    handleModelClose();
    quickPassenger.resetFields();
  };

  const handlePassengerTypeChange = (e: RadioChangeEvent) => {
    setPassengerType(e.target.value);
  };

  return (
    <Modal
      open={isOpen}
      width={500}
      footer={null}
      centered
      className={styleCss.addNewPassengerModel}
      closable={true}
      onCancel={handleModelClose}
    >
      <Typography.Title className="modelTitle" style={{ marginBottom: "0px" }}>
        {type === QUICK_MODEL_TYPE.secondStepPassenger ? "Add Passenger" : "Add Client"}
      </Typography.Title>

      <Form
        name="quickPassenger"
        form={quickPassenger}
        layout="vertical"
        onFinish={onFinish}
        className="formContainer"
        initialValues={{ passengerType: "INDIVIDUAL" }}
      >
        {type === QUICK_MODEL_TYPE.secondStepPassenger && (
          <Form.Item name="passengerType">
            <Radio.Group
              style={{ display: "flex", alignItems: "center" }}
              onChange={handlePassengerTypeChange}
              value={passengerType}
            >
              <Radio value="INDIVIDUAL" defaultChecked>
                Individual
              </Radio>
              <Divider style={{ margin: "0px 14px 0px 8px", borderInlineStartColor: "#00000026" }} type="vertical" />
              <Radio value="FAMILY">In Family</Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Row gutter={[20, 0]}>
          {passengerType === "FAMILY" && (
            <Col span={24}>
              <InputSelect
                name="member"
                options={referenceList?.map((refDt) => ({
                  label: refDt.contact_name || refDt.full_name,
                  value: refDt._id,
                }))}
                allowClear
                label="Member"
                placeholder="Select Member"
                showSearch
                onChangeHandle={(value) => console.log("value", value)}
                rules={[{ required: true }]}
              />
            </Col>
          )}
          <Col span={24}>
            <InputBox
              name="fullName"
              label="Name (First & Last Name)"
              placeholder="Enter name"
              // onChange={handleFullNameChange}
              rules={[{ required: true }]}
              onBlur={(e) => {
                quickPassenger.setFieldValue("fullName", capitalizeFirstLetterOfWord(e.target.value));
              }}
            />
          </Col>
          {passengerType === "INDIVIDUAL" && (
            <>
              <Col span={24}>
                <ContactInput
                  form={quickPassenger}
                  name="contactNumber"
                  label="Contact Number"
                  onChange={(value) => {
                    // dispatch(setMobileNumber(value));
                  }}
                />
              </Col>

              <Col span={24}>
                <InputSelect
                  name="referenceBy"
                  options={referenceList?.map((refDt) => ({
                    label: refDt.contact_name || refDt.full_name,
                    value: refDt._id,
                  }))}
                  allowClear
                  label="Reference By"
                  placeholder="Select Reference"
                  showSearch
                  onChangeHandle={(value) => console.log("value", value)}
                />
              </Col>
            </>
          )}
        </Row>

        <Flex gap={15} justify="end" className="btn">
          <Button onClick={quickModalClose}>Cancel</Button>
          <Button htmlType="submit">Save</Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default QuickPassengerModel;
