import React, { useState } from "react";
import { Modal, Button, Typography, Flex } from "antd";
import { IoMdClose } from "react-icons/io";
// import styleCss from "./style.module.scss";

interface CloseIconWithConfirmationProps {
  message: string;
  onConfirm: () => void;
  confirmText?: string;
  cancelText?: string;
  iconProps?: React.ComponentProps<typeof IoMdClose>;
}

const CloseIconWithConfirmation: React.FC<CloseIconWithConfirmationProps> = ({
  message,
  onConfirm,
  confirmText = "Delete",
  cancelText = "Cancel",
  iconProps,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleIconClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleConfirm = () => {
    setIsModalVisible(false);
    onConfirm();
  };

  return (
    <>
      <IoMdClose {...iconProps} onClick={handleIconClick} />
      <Modal
        centered
        open={isModalVisible}
        onCancel={handleCancel}
        width={430}
        footer={null}
        closable={false}
        // className={styleCss.confirmDelete}
      >
        <Typography>{message}</Typography>
        <Flex justify="end" style={{ marginTop: "16px" }}>
          <Button onClick={handleCancel} className="cancelBtn">
            {cancelText}
          </Button>
          <Button onClick={handleConfirm} className="deleteBtn">
            {confirmText}
          </Button>
        </Flex>
      </Modal>
    </>
  );
};

export default CloseIconWithConfirmation;
