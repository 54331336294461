import { Button, Flex, Form, message, Table } from "antd";
import {
  addItem,
  fetchItemList,
  IItems,
  resetAllFormState,
  setItemData,
  updateItemList,
} from "../../../slices/Account/itemSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ItemForm from "./ItemForm";
import commonStyle from "../../../css/commonStyle.module.scss";
import { setAccountItemDrawerOpen } from "../../../slices/layoutSlice";
import DataTable from "../../../components/DataTable";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import _ from "lodash";

interface ICreateItem {
  itemId?: string;
}

const AddItemForm = ({ itemId }: ICreateItem) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const { loading, allItemList } = useSelector((state: RootState) => state.accountItem);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [gstApplicable, setGstApplicable] = useState("");

  useEffect(() => {
    if (!itemId) return;
    const filteredItem = allItemList.find((item) => itemId === item._id);
    dispatch(setItemData(filteredItem));
  }, [itemId]);

  useEffect(() => {
    dispatch(fetchItemList());
  }, []);

  const drawerClose = () => {
    form.resetFields();
    if (itemId) {
      setTimeout(() => {
        dispatch(resetAllFormState());
      }, 300);
    } else {
      dispatch(resetAllFormState());
      form.resetFields();
      dispatch(setAccountItemDrawerOpen(null));
    }
  };

  const handleFinish = async (val: IItems) => {
    const itemData: any = {
      name: val?.name,
      unit: val?.unit,
      sac: val?.sac,
      gst_applicable: val?.gst_applicable,
      gst_rate: val?.gst_rate,
    };

    if (editingItemId) {
      const response: any = await dispatch(updateItemList({ id: editingItemId, updatedData: itemData }));
      if (response) {
        setEditingItemId(null);
        form.resetFields();
        setGstApplicable("");
      }
    } else {
      const response: any = await dispatch(addItem(itemData));
      if (response) {
        drawerClose();
        fetchItemList();
        setGstApplicable("");
      } else {
        message.error(response.payload?.error?.message);
      }
    }
  };

  const handleFinishFailed = (errorInfo: any) => {
    if (errorInfo && errorInfo?.errorFields?.length > 0) {
      message.error("Please enter data in all required fields.");
    }
  };

  const handleEdit = (id: string) => {
    const itemToEdit = allItemList.find((item) => item._id === id);
    if (itemToEdit) {
      setEditingItemId(id);
      setFieldValues(itemToEdit);
    }
  };

  const setFieldValues = (item: any) => {
    const { _id } = item;

    if (_id) {
      form.setFieldValue("name", _.get(item, "name", ""));
      form.setFieldValue("gst_applicable", _.get(item, "gst_applicable", ""));
      const gstApplicable = _.get(item, "gst_applicable", "");
      if (gstApplicable === "Non Applicable") {
        setGstApplicable("Non Applicable");
      } else {
        setGstApplicable("Applicable");
      }
      const gstRate = _.get(item, "gst_rate", "");
      let formattedGstRate = "";
      if (gstRate === 5) {
        formattedGstRate = "5% (SGST 2.5% + CGST 2.5%)";
      } else if (gstRate === 18) {
        formattedGstRate = "18% (SGST 9% + CGST 9%)";
      }
      form.setFieldValue("gst_rate", formattedGstRate);
      form.setFieldValue("sac", _.get(item, "sac", ""));
      form.setFieldValue("unit", _.get(item, "unit", ""));
    }
  };

  const column: any[] = [
    Table.SELECTION_COLUMN,
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
      width: "100%",
    },
    {
      title: "",
      key: "actions",
      render: (_: any, record: any) => (
        <Flex align="center" gap={8} justify="end">
          <span style={{ cursor: "pointer" }}>
            <EditOutlined onClick={() => handleEdit(record._id)} />
          </span>
          <span style={{ cursor: "pointer" }}>
            <DeleteOutlined style={{ color: "red" }} />
          </span>
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Form
        form={form}
        name="dynamic_form"
        autoComplete="off"
        layout="vertical"
        onFinish={handleFinish}
        onFinishFailed={handleFinishFailed}
        scrollToFirstError
      >
        <div>
          <ItemForm form={form} gstApplicable={gstApplicable} setGstApplicable={setGstApplicable} />
          <Flex gap={15} justify="end" className="pr-6">
            <Button className={commonStyle.primaryBtn} htmlType="submit" loading={loading}>
              {editingItemId ? "Update" : "Add"}
            </Button>
          </Flex>
          <div style={{ cursor: "pointer" }} className="pr-6 pl-6 pt-4">
            <DataTable column={column} data={allItemList} loading={loading} pagination={false} />
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddItemForm;
