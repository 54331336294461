import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axiosInstance";

export const addItem = createAsyncThunk("account-item/create", async (newItem: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_ACCOUNT_SERVICE}/item/create`, newItem);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const fetchItemList = createAsyncThunk("item/list", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_ACCOUNT_SERVICE}/item/list`);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const updateItemList = createAsyncThunk(
  "item/update",
  async ({ id, updatedData }: { id: string; updatedData: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${process.env.REACT_APP_ACCOUNT_SERVICE}/item/edit/${id}`, updatedData);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export interface IItems {
  name: string;
  unit: "pax" | "qty";
  sac: string;
  gst_applicable: "Applicable" | "Non Applicable";
  gst_rate: Number;
  _id: string;
}

interface IItemState {
  loading: any;
  error: string | null;
  allItemList: IItems[];
  ItemData: IItems | any;
}

const initialState: IItemState = {
  loading: false,
  error: null,
  allItemList: [],
  ItemData: {
    name: "",
    unit: "",
    sac: "",
    gstApplicable: "",
    gstRate: "",
  },
};

const itemSlice = createSlice({
  name: "Item",
  initialState,
  reducers: {
    setItemData: (state, action) => {
      state.ItemData = action.payload;
    },
    resetAllFormState: (state) => {
      state.ItemData = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addItem.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addItem.fulfilled, (state, action) => {
      state.loading = false;
      state.allItemList.push(action.payload);
    });
    builder.addCase(addItem.rejected, (state, action) => {
      state.loading = false;
      const errData: any = action.payload;
      state.error = errData.message as string;
    });
    builder.addCase(fetchItemList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchItemList.fulfilled, (state, action) => {
      state.loading = false;
      state.allItemList = action.payload;
    });
    builder.addCase(fetchItemList.rejected, (state, action) => {
      state.loading = false;
      state.allItemList = [];
    });
    builder.addCase(updateItemList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateItemList.fulfilled, (state, action) => {
      const { _id } = action.payload;
      state.allItemList = state.allItemList.map((item) => {
        if (item._id === _id) {
          return action.payload;
        } else {
          return item;
        }
      });

      state.loading = false;
    });
    builder.addCase(updateItemList.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setItemData, resetAllFormState } = itemSlice.actions;
export default itemSlice.reducer;
