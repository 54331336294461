import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Button, Col, Collapse, Divider, Flex, Image, Row, Typography, Upload, UploadProps } from "antd";
import { cities, country, getStateByCity, stateList } from "../../../utils/data";
import { setIsUploadCollapse, setMobileNumber, setProfileImage, setReferenceBy } from "../../../slices/contactSlice";
import { AppDispatch, RootState } from "../../../store";
import { InputBox, InputSelect } from "../../../components/FormInput";
import ContactInput from "../../../components/FormInput/contactInput";
import FormDocument from "./FormDocument";
import pencilSVG from "../../../assets/pencil.svg";
import personSVG from "../../../assets/personAvtar.svg";
import { PlusOutlined } from "@ant-design/icons";
import FormCss from "./form.module.scss";
import { setIsFiledVisible } from "../../../slices/vendorSlice";
import { IoMdMore } from "react-icons/io";
import { capitalizeFirstLetterOfWord } from "../../../utils/commonFunctions";

const state = stateList();
const { Title } = Typography;
const { Panel } = Collapse;

interface ContactFormPropsInterface {
  formId: string;
  form: any;
}

const ContactForm = ({ formId, form }: ContactFormPropsInterface) => {
  const dispatch = useDispatch<AppDispatch>();
  const { profileImage, referenceList, mobileNumber, referenceBy, isUploadCollapse, isFiledVisible } = useSelector(
    (state: RootState) => state.contact    
  );
  const [previewImage, setPreviewImage] = useState<string | undefined>(personSVG);
  const [nameUpdated, setNameUpdated] = useState(false);
  const scrollToElementRef = useRef<HTMLDivElement>(null);

  const updateField = (fieldName: string, value: string | number | boolean | Date | null) => {
    form.setFieldValue([formId, fieldName], value);
  };

  const handleContactNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setNameUpdated(false);
    }
    updateField("contact_name", value);
    if (!nameUpdated) {
      updateField("full_name", value);
    }
  };

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    updateField("full_name", value);

    if (!form.getFieldValue([formId, "contact_name"])) {
      updateField("contact_name", value);
    }
    setNameUpdated(true);
  };

  const selectStateBaseOnCity = (val: string) => {
    const stateValue = getStateByCity(val)?.trim();
    form.setFieldValue([formId, "state"], stateValue ? stateValue : null);
  };

  useEffect(() => {
    form.setFieldValue([formId, "country"], "IN");
    const imageUrl = profileImage[formId]?.url;
    if (imageUrl) {
      setPreviewImage(imageUrl);
    }

    const fullName = form.getFieldValue([formId, "full_name"]);
    if (fullName) {
      setNameUpdated(true);
    }

    let referenceByValue = form.getFieldValue([formId, "reference_by"]);
    if (referenceBy && (!referenceByValue || _.trim(referenceByValue) === "")) {
      form.setFieldValue([formId, "reference_by"], referenceBy);
    } else if (_.trim(referenceByValue) === "") {
      form.setFieldValue([formId, "reference_by"], null);
    }

    const contactNumber = form.getFieldValue([formId, "contact_number"]);
    if (mobileNumber && !contactNumber) {
      form.setFieldValue([formId, "contact_number"], mobileNumber);
    }
  }, [form, formId, profileImage, referenceBy, mobileNumber]);

  useEffect(() => {
    if (isUploadCollapse && scrollToElementRef.current) {
      scrollToElementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isUploadCollapse]);

  const getBase64 = (file: File, callback: (imageUrl: string) => void) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result as string);
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    dispatch(setProfileImage({ formId, images: newFileList }));
    if (newFileList.length > 0) {
      getBase64(newFileList[0].originFileObj as File, (imageUrl: string) => {
        setPreviewImage(imageUrl);
      });
    } else {
      setPreviewImage(personSVG);
    }
  };

  const onChange = () => {
    dispatch(setIsUploadCollapse(!isUploadCollapse));
  };

  const updateShowFiled = (fieldName: "alias" | "tds" | "address" | "paymentTerms", isVisible: boolean) => {
    
    dispatch(setIsFiledVisible({ fieldName, isVisible }));
  };

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setNameUpdated(false);
    }
    updateField("contact_name", value);
    if (!nameUpdated) {
      updateField("full_name", value);
    }
  };
  return (
    <div className={FormCss.FormComponent}>
      <div className="pt-5 pb-5 pr-5 pl-5">
        <Row gutter={[20, 0]}>
          <Col span={4}>
            <div className={FormCss.avatar}>
              <Image style={{ borderRadius: 5 }} src={previewImage} preview={false} alt="Profile Image" />
              <Upload
                maxCount={1}
                className="upload-button"
                fileList={[]}
                onChange={handleChange}
                accept=".png, .jpeg, .jpg"
                beforeUpload={() => false}
              >
                <div>
                  <Image src={pencilSVG} preview={false} alt="Upload" />
                </div>
              </Upload>
            </div>
          </Col>
          <Col span={20}>
            <InputBox
              className="input-field"
              name={[formId, "contact_name"]}
              label="Display name as"
              placeholder="Enter name"
              onChange={handleContactNameChange}
              rules={[{ required: true }]}
              onBlur={(e) => {
                updateField("contact_name", capitalizeFirstLetterOfWord(e.target.value));
                updateField("full_name", capitalizeFirstLetterOfWord(e.target.value));
              }}
            />
          </Col>

          <Col span={12}>
            <InputBox
              className="input-field"
              name={[formId, "full_name"]}
              label="Name (First & Last Name)"
              placeholder="Enter name"
              onChange={handleFullNameChange}
              rules={[{ required: true }]}
            />
          </Col>

          <Col span={12}>
            <InputSelect
              name={[formId, "reference_by"]}
              options={referenceList?.map((refDt) => ({
                label: refDt.contact_name || refDt.full_name,
                value: refDt._id,
              }))}
              allowClear
              label="Reference By"
              placeholder="Select Reference"
              showSearch
              onChangeHandle={(value) => dispatch(setReferenceBy(value))}
            />
          </Col>
          <Col span={12}>
            <ContactInput
              form={form}
              name={[formId, "contact_number"]}
              label="Contact Number"
              onChange={(value) => {
                dispatch(setMobileNumber(value));
              }}
            />
          </Col>
          <Col span={12}>
            <InputBox
              rules={[{ type: "email" }]}
              className="input-field"
              name={[formId, "email"]}
              label="Email"
              placeholder="Enter Email"
            />
          </Col>
          <Col span={24}>
            <InputBox
              className="input-field"
              name={[formId, "residents_address"]}
              label="Current Residents Address"
              placeholder="Enter address"
            />
          </Col>
          <Col span={12}>
            <InputSelect
              name={[formId, "city"]}
              options={
                cities.map((ct) => ({
                  label: ct.name,
                  value: ct.name,
                })) as { label: string; value: string }[]
              }
              label="City"
              placeholder="Select City"
              showSearch
              onChangeHandle={selectStateBaseOnCity}
            />
          </Col>
          <Col span={12}>
            <InputBox
              className="input-field"
              name={[formId, "pincode"]}
              label="Pin Code"
              placeholder="Enter Pin Code"
            />
          </Col>
          <Col span={12}>
            <InputSelect
              name={[formId, "state"]}
              options={state.map((st: string) => ({
                label: st,
                value: st,
              }))}
              label="State"
              placeholder="Select State"
              showSearch
              defaultValue={form.getFieldValue([formId, "state"])?.trim() || null}
            />
          </Col>
          <Col span={12}>
            <InputSelect
              name={[formId, "country"]}
              options={country.map((ct) => ({
                label: ct.name,
                value: ct.code,
              }))}
              label="Country"
              placeholder="Select Country"
              showSearch
            />
          </Col>
        </Row>
        {/* <Row gutter={[4, 8]} align={"middle"}>
          <Col span={6}>
            <Typography className="titleMendetory">Disply name as</Typography>
          </Col>
          <Col span={16}>
            <InputBox
              className="input-field"
              name={[formId, "contact_name"]}
              placeholder="Enter name"
              onChange={handleContactNameChange}
              rules={[{ required: true }]}
              style={{ width: "80%" }}
            />
          </Col>
          
          <Col span={6}>
            <Typography className="titleField">Alias</Typography>
          </Col>
          <Col span={16}>
            {!isFiledVisible?.alias ? (
              <Button
                type="link"
                style={{ color: "#30bfee", boxShadow: "none" }}
                onClick={() => updateShowFiled("alias", true)}
              >
                Add Alias
              </Button>
            ) : (
              <Flex align="center">
                <InputBox
                  className="input-field width"
                  name={[formId, "full_name"]}
                  placeholder="Enter Nickname"
                  onChange={handleAccountNameChange}
                  style={{ width: "80%" }}
                />
                <IoMdMore size={24} color="#d9d9d9" />
              </Flex>
            )}
          </Col>
          <Col span={16}>
            {!isFiledVisible?.alias ? (
              <Button
                type="link"
                style={{ color: "#30bfee", boxShadow: "none" }}
                onClick={() => updateShowFiled("alias", true)}
              >
                Add Alias
              </Button>
            ) : (
              <Flex align="center">
                <InputBox
                  className="input-field width"
                  name={[formId, "full_name"]}
                  placeholder="Enter Nickname"
                  onChange={handleAccountNameChange}
                  style={{ width: "80%" }}
                />
                <IoMdMore size={24} color="#d9d9d9" />
              </Flex>
            )}
          </Col>
        </Row> */}
      </div>
      <Divider
        style={{
          borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
          margin: 0,
        }}
      />

      <div className="uploadDoc">
        <Collapse ghost bordered={false} style={{ padding: 0 }} activeKey={isUploadCollapse ? ["1"] : []}>
          <Panel
            showArrow={false}
            header={
              <Button onClick={onChange} type="primary" className={`addButton`} block >
                Upload Document
              </Button>
            }
            key="1"
            ref={scrollToElementRef}
          >
            <FormDocument form={form} formId={formId} />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default ContactForm;
