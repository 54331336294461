import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Layout } from "antd";
import { RootState } from "../store";
import HeaderLayout from "./Layout/Header/HeaderLayout";
import FooterLayout from "./Layout/Footer/Footer";
import SideBarLayout from "./Layout/SideBar/SideBarLayout";
import RightSideBar from "./Layout/RightSideBar/RightSideBar";
import TaskDetails from "../pages/TaskAndInquiry/Task/TaskDetails";
import CreateContact from "../pages/ContactList/CreateContact";
import CreateVendor from "../pages/VendorList/CreateVendor";
import CreareItem from "../pages/Account/Item/CreateItem";

const { Content } = Layout;
interface UserLayoutInterFace {
  children: ReactNode;
}

const UserLayout = ({ children }: UserLayoutInterFace) => {
  const { sidebarOpen } = useSelector((state: RootState) => state.layout);
  const { openItemList } = useSelector((state: RootState) => state.minimize);

  const [textVisible, setTextVisible] = useState(true);

  useEffect(() => {
    if (sidebarOpen) {
      setTextVisible(true);
    } else {
      const timer = setTimeout(() => setTextVisible(false), 200);
      return () => clearTimeout(timer);
    }
  }, [sidebarOpen]);

  return (
    <Layout className="userLayout">
      <SideBarLayout />
      <Layout
        style={{ backgroundColor: "transparent", transition: "all 0.3s ease-in-out" }}
        className={`${sidebarOpen ? "layoutSideBarOpen" : "layoutSideBarClose"}`}
      >
        <HeaderLayout />
        <Layout style={{ minHeight: `calc(100vh - ${70 + 63}px)` }}>
          <Content style={{ backgroundColor: "transparent" }}>
            <div className="container">{children}</div>
          </Content>
        </Layout>
        <FooterLayout />
      </Layout>
      <RightSideBar />

      {openItemList?.map((itm, index) => {
        if (itm.type === "TASK") {
          return <TaskDetails key={itm._id} id={itm._id} layer={index} />;
        } else if (itm.type === "CONTACT") {
          return <CreateContact key={itm._id} contactId={itm._id} />;
        } else if (itm.type === "VENDOR") {
          return <CreateVendor key={itm._id} vendorId={itm._id} />;
        } else if (itm.type === "ITEM") {
          return <CreareItem key={itm._id} itemId={itm._id} />;
        }
      })}
    </Layout>
  );
};

export default UserLayout;
