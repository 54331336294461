import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, Col, Flex, Form, Row, Select } from "antd";
import { cities, getStateByCity } from "../../../utils/data";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { InputBox, InputRadio, InputSelect } from "../../../components/FormInput";
import _ from "lodash";
import Typography from "antd/es/typography/Typography";
import { IoIosArrowDown, IoIosCloseCircleOutline } from "react-icons/io";
import ContactPersonPopover from "./ContactPersonPopover";
import {
  fetchGstDetails,
  IIsFiledShow,
  setGstDocs,
  setIsFiledVisible,
  setOtherDocs,
  setPanDocs,
} from "../../../slices/vendorSlice";
import BankPopover from "./BankPopover";
import {
  balance,
  currency,
  GstTreatmentOptions,
  paymentTerms,
  taxOptions,
  validateGST,
  validatePAN,
} from "../../../utils/const";
import UploadDoc from "./UploadDoc";
import { LuInfo } from "react-icons/lu";
import FormCss from "./form.module.scss";
import { capitalizeFirstLetterOfWord } from "../../../utils/commonFunctions";

const VendorForm = forwardRef((props: any, ref: any) => {
  const { Option } = Select;
  const dispatch = useDispatch<AppDispatch>();
  const { isUploadCollapse, isFiledVisible, vendorData } = useSelector((state: RootState) => state.vendor);
  const { vendorDrawerOpen } = useSelector((state: RootState) => state.layout);
  const [nameUpdated, setNameUpdated] = useState(false);
  const [gstTreatment, setGstTreatment] = useState<string | null>("Unregistered Business");
  const scrollToElementRef = useRef<HTMLDivElement>(null);
  const [balanceType, setBalanceType] = useState("credit");
  const [uploadedGstFiles, setUploadedGstFiles] = useState<any[]>([]);
  const [uploadedPanFiles, setUploadedPanFiles] = useState<any[]>([]);
  const [uploadedOtherFiles, setUploadedOtherFiles] = useState<any[]>([]);
  const [accountType, setAccountType] = useState("VENDOR");
  const [GSTNumber, setGSTNumber] = useState("");

  const handleGstTreatmentChange = (value: string) => {
    setGstTreatment(value);
    props.form.setFieldValue("gst_treatment", value);
  };

  useEffect(() => {
    if (!vendorDrawerOpen) {
      setUploadedGstFiles([]);
      setUploadedPanFiles([]);
      setUploadedOtherFiles([]);
    }
  }, [vendorDrawerOpen]);

  useEffect(() => {
    if (!vendorData) return;

    const { gst, alias, address, tds, payment_terms, _id } = vendorData;
    if (gst) {
      setGSTNumber(gst.gst_number || "");
      if (_id) {
        const gstTreatment = gst.gst_treatment || "Unregistered Business";
        setGstTreatment(gstTreatment);
        props.form.setFieldValue("gst_treatment", gstTreatment);
      }
    }

    const fieldVisibilityMap: { field: keyof IIsFiledShow; condition: any }[] = [
      { field: "alias", condition: alias },
      { field: "address", condition: address?.office_address },
      { field: "tds", condition: tds },
      { field: "paymentTerms", condition: payment_terms },
    ];

    fieldVisibilityMap?.forEach(({ field, condition }) => {
      if (condition) {
        dispatch(setIsFiledVisible({ fieldName: field, isVisible: true }));
      }
    });
  }, [vendorData]);

  const updateField = (fieldName: string, value: string | number | boolean | Date | null) => {
    props.form.setFieldValue(fieldName, value);
  };

  const handleAccountNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setNameUpdated(false);
    }
    updateField("contact_name", value);
    if (!nameUpdated) {
      updateField("full_name", value);
    }
  };

  const handleAccountTypeChange = (e: any) => {
    setAccountType(e.target.value);
  };

  const selectStateBaseOnCity = (val: string) => {
    const stateValue = getStateByCity(val)?.trim();
    props.form.setFieldValue("state", stateValue ? stateValue : null);
  };

  useEffect(() => {
    props.form.setFieldValue("country", "IN");

    const fullName = props.form.getFieldValue("full_name");
    if (fullName) {
      setNameUpdated(true);
    }
  }, [props.form]);

  useImperativeHandle(ref, () => ({
    resetFields() {
      props.form.resetFields();
      setUploadedGstFiles([]);
    },
  }));

  useEffect(() => {
    if (isUploadCollapse && scrollToElementRef.current) {
      scrollToElementRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [isUploadCollapse]);

  const updateShowFiled = (fieldName: "alias" | "tds" | "address" | "paymentTerms", isVisible: boolean) => {
    dispatch(setIsFiledVisible({ fieldName, isVisible }));
  };

  const handleGstUploadChange = (fileList: any[]) => {
    setUploadedGstFiles(fileList);
    dispatch(setGstDocs(fileList));
  };

  const handlePanUploadChange = (fileList: any[]) => {
    setUploadedPanFiles(fileList);
    dispatch(setPanDocs(fileList));
  };

  const handleOtherUploadChange = (fileList: any[]) => {
    setUploadedOtherFiles(fileList);
    dispatch(setOtherDocs(fileList));
  };

  const handleFetchDetails = async () => {
    await dispatch(fetchGstDetails(GSTNumber));
  };

  return (
    <div className={FormCss.FormComponent}>
      <div className="pt-5 pb-5 pl-6">
        <Row gutter={[4, 8]} align={"middle"}>
          <Col span={6}>
            <Typography className="titleField">Account Type</Typography>
          </Col>
          <Col span={16}>
            <InputRadio
              name="user_type"
              className="input-radio"
              options={[
                { value: "VENDOR", label: "VENDOR" },
                { value: "CLIENT", label: "CLIENT" },
              ]}
              onChange={handleAccountTypeChange}
              defaultValue="VENDOR"
            />
          </Col>

          <Col span={6}>
            <Flex align="center" gap={6}>
              <Typography className="titleMendetory">GST Treatment*</Typography>
              <LuInfo color="#a3a3a3" />
            </Flex>
          </Col>
          <Col span={16}>
            <Flex align="center">
              <Form.Item
                name={"gst_treatment"}
                rules={[
                  {
                    required: true,
                    message: "Please enter GST Treatment!",
                  },
                ]}
                style={{ width: "95%" }}
                initialValue="Unregistered Business"
              >
                <Select
                  size="large"
                  placeholder="Select a GST treatment"
                  optionLabelProp="label"
                  onChange={handleGstTreatmentChange}
                  suffixIcon={<IoIosArrowDown size={16} />}
                  dropdownStyle={{ borderRadius: "4px" }}
                  defaultValue="Unregistered Business"
                >
                  {GstTreatmentOptions?.map((option, index) => (
                    <Option key={index} value={option.label} title={option.label} name={"gst_treatment"}>
                      <div>
                        <Typography style={{ fontWeight: "500", lineHeight: "normal" }}>{option.label}</Typography>
                        <Typography
                          style={{
                            fontSize: "12px",
                            color: "#a7a7a7",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            fontWeight: "500",
                          }}
                        >
                          {option?.subLabel}
                        </Typography>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Flex>
          </Col>

          {gstTreatment !== "Unregistered Business" && (
            <>
              <Col span={6}>
                <Typography className="titleMendetory">GST No*</Typography>
              </Col>
              <Col span={17}>
                <Flex gap={10} className="gstNoField" align="flex-start">
                  <InputBox
                    addonAfter={<Button onClick={handleFetchDetails}>Auto Fill</Button>}
                    placeholder="Enter GST number"
                    name={"gst_number"}
                    className="input-field width50"
                    onChange={(e: any) => setGSTNumber(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: "Please enter GST number!",
                      },
                      {
                        validator: validateGST,
                      },
                    ]}
                  />
                  <UploadDoc
                    fieldName="gstDoc"
                    uploadedFiles={uploadedGstFiles}
                    onUploadChange={handleGstUploadChange}
                  />
                </Flex>
              </Col>
            </>
          )}

          <Col span={6}>
            <Typography className="titleMendetory">
              {accountType === "VENDOR" ? "Vendor A/C Name*" : "Client A/C Name*"}
            </Typography>
          </Col>
          <Col span={16}>
            <InputBox
              className="input-field"
              name={"account_name"}
              placeholder="Enter Account name"
              onChange={handleAccountNameChange}
              rules={[{ required: true }]}
              style={{ width: "95%" }}
              onBlur={(e) => {
                updateField("account_name", capitalizeFirstLetterOfWord(e.target.value));
              }}
            />
          </Col>

          <Col span={6}>
            <Flex align="center" gap={6}>
              <Typography className="titleField">Alias</Typography>
              {/* <Popover title='info'> */}
              <LuInfo color="#a3a3a3" />
              {/* </Popover> */}
            </Flex>
          </Col>
          <Col span={16} className="ledgerField">
            {!isFiledVisible?.alias ? (
              <Button type="link" style={{ boxShadow: "none" }} onClick={() => updateShowFiled("alias", true)}>
                Add Alias
              </Button>
            ) : (
              <Flex align="center" gap={5}>
                <InputBox
                  className="input-field width95"
                  name={"nick_name"}
                  placeholder="Enter Nickname"
                  onChange={handleAccountNameChange}
                  onBlur={(e) => {
                    updateField("nick_name", capitalizeFirstLetterOfWord(e.target.value));
                  }}
                />
                <IoIosCloseCircleOutline
                  size={18}
                  color="#ff000375"
                  style={{ cursor: "pointer" }}
                  onClick={() => updateShowFiled("alias", false)}
                  className="closeIcon"
                />
              </Flex>
            )}
          </Col>

          <Col span={6}>
            <Flex align="center" gap={6}>
              <Typography className="titleMendetory">PAN*</Typography>
              <LuInfo color="#a3a3a3" />
            </Flex>
          </Col>
          <Col span={17}>
            <Flex gap={6} align="flex-start">
              <InputBox
                className="input-field width50"
                name={"pan_number"}
                placeholder="Enter PAN number"
                rules={[
                  {
                    required: true,
                    message: "Please enter PAN number!",
                  },
                  {
                    validator: validatePAN,
                  },
                ]}
              />
              <UploadDoc fieldName="panDoc" uploadedFiles={uploadedPanFiles} onUploadChange={handlePanUploadChange} />
            </Flex>
          </Col>

          <Col span={6}>
            <Flex align="center" gap={6}>
              <Typography className="titleField">TDS</Typography>
              <LuInfo color="#a3a3a3" />
            </Flex>
          </Col>
          <Col span={16} className="ledgerField">
            {!isFiledVisible?.tds ? (
              <Button type="link" style={{ boxShadow: "none" }} onClick={() => updateShowFiled("tds", true)}>
                Add TDS
              </Button>
            ) : (
              <Flex align="center" className="MoreWidth" gap={5}>
                <InputSelect
                  name={"tds"}
                  options={taxOptions}
                  allowClear
                  placeholder="Select a Tax"
                  className={"widthh"}
                />
                <IoIosCloseCircleOutline
                  size={18}
                  color="#ff000375"
                  style={{ cursor: "pointer" }}
                  onClick={() => updateShowFiled("tds", false)}
                  className="closeIcon"
                />
              </Flex>
            )}
          </Col>

          <Col span={6}>
            <Typography className="titleField">Address</Typography>
          </Col>
          {!isFiledVisible?.address ? (
            <Col span={16}>
              <Button type="link" style={{ boxShadow: "none" }} onClick={() => updateShowFiled("address", true)}>
                Add Address
              </Button>
            </Col>
          ) : (
            <>
              <Col span={16} className="ledgerField">
                <Flex align="center" gap={5}>
                  <InputBox className="input-field width95" name={"office_address"} placeholder="Enter address" />
                  <IoIosCloseCircleOutline
                    size={18}
                    color="#ff000375"
                    style={{ cursor: "pointer" }}
                    onClick={() => updateShowFiled("address", false)}
                    className="closeIcon"
                  />
                </Flex>
              </Col>
              <Col span={6} className="titleField"></Col>
              <Col span={16}>
                <Flex gap={6} className="address">
                  <InputSelect
                    name={"city"}
                    options={
                      cities.map((ct) => ({
                        label: ct.name,
                        value: ct.name,
                      })) as { label: string; value: string }[]
                    }
                    placeholder="Select City"
                    showSearch
                    onChangeHandle={selectStateBaseOnCity}
                  />
                  <InputBox className="input-field" name={"pincode"} placeholder="Enter Pin Code" />
                </Flex>
              </Col>
            </>
          )}

          <Col span={6}>
            <Flex align="center" gap={6}>
              <Typography className="titleMendetory">Source of Supply*</Typography>
              <LuInfo color="#a3a3a3" />
            </Flex>
          </Col>
          <Col span={16}>
            <Form.Item
              name={"source_of_supply"}
              rules={[{ required: true, message: "Please enter Source of Supply!" }]}
              initialValue={"Gujarat"}
            >
              <InputBox className="input-field" placeholder="Enter Source of Supply" style={{ width: "95%" }} />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Typography className="titleField">Opening Balance</Typography>
          </Col>
          <Col span={16} className={`${balanceType === "credit" ? "creditColor" : "debitColor"}`}>
            <InputBox
              addonBefore={
                <InputSelect
                  defaultValue="Cr"
                  name={"balance_type"}
                  options={balance}
                  style={{ width: "auto" }}
                  onChangeHandle={(value) => setBalanceType(value)}
                />
              }
              addonAfter={
                <InputSelect
                  defaultValue="INR"
                  name={"currency"}
                  options={currency}
                  style={{ width: "auto" }}
                  showSearch
                  IconColor="white"
                />
              }
              placeholder="Enter Balance"
              name="balance"
              style={{ width: "60%" }}
              defaultValue={"00"}
            />
          </Col>

          <Col span={6}>
            <Typography className="titleField">Contact Person</Typography>
          </Col>
          <Col span={16}>
            <ContactPersonPopover />
          </Col>
          <Col span={6}>
            <Typography className="titleField">Bank Details</Typography>
          </Col>
          <Col span={16}>
            <BankPopover />
          </Col>

          <Col span={6}>
            <Typography className="titleField">Payment Terms</Typography>
          </Col>
          <Col span={16} className="ledgerField">
            {!isFiledVisible?.paymentTerms ? (
              <Button type="link" style={{ boxShadow: "none" }} onClick={() => updateShowFiled("paymentTerms", true)}>
                Add Payment Terms
              </Button>
            ) : (
              <Flex align="center" className="MoreWidth" gap={5}>
                <InputSelect name={"payment_terms"} options={paymentTerms} placeholder="Select a Terms" allowClear />

                <IoIosCloseCircleOutline
                  size={18}
                  color="#ff000375"
                  style={{ cursor: "pointer" }}
                  onClick={() => updateShowFiled("paymentTerms", false)}
                  className="closeIcon"
                />
              </Flex>
            )}
          </Col>

          <Col span={6}>
            <Typography className="titleField">Other Documents</Typography>
          </Col>
          <Col span={16}>
            <UploadDoc
              fieldName="otherDoc"
              uploadedFiles={uploadedOtherFiles}
              onUploadChange={handleOtherUploadChange}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
});

export default VendorForm;
