import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../utils/axiosInstance";

export const addVendor = createAsyncThunk("vendor/create", async (newPassenger: any, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.post(`${process.env.REACT_APP_LEDGER_SERVICE}/create`, newPassenger);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data);
  }
});

export const fetchVendorList = createAsyncThunk("vendor/list", async (_, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_LEDGER_SERVICE}/list`);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const getVendorDetails = createAsyncThunk("vendor/get", async (id: string, { rejectWithValue }) => {
  try {
    const response = await axiosInstance.get(`${process.env.REACT_APP_LEDGER_SERVICE}/get/${id}`);
    return response?.data?.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data?.message || error.message);
  }
});

export const updateVendorList = createAsyncThunk(
  "vendor/update",
  async ({ id, updatedData }: { id: string; updatedData: any }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${process.env.REACT_APP_LEDGER_SERVICE}/update/${id}`, updatedData);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const fetchGstDetails = createAsyncThunk(
  "vendor/getGstDetails",
  async (gstNumber: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_LEDGER_SERVICE}/get-payerdata/${gstNumber}`);
      return response?.data?.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export interface IIsFiledShow {
  alias: boolean;
  tds: boolean;
  address: boolean;
  paymentTerms: boolean;
}

export interface ILedgerData {
  gst: IGstInfo;
  pan: IPanInfo;
  address?: IAddressInfo;
  opening_balance: IOpeningBalance;
  _id?: string;
  account_type: string;
  account_name: string;
  alias?: string;
  tds?: string;
  source_of_supply: string;
  payment_terms?: string;
  contact_members?: IContactMember[];
  bank_details?: IBankDetail[];
  documents?: IDocument[];
}

export interface IGstInfo {
  gst_treatment: string;
  gst_number: string;
  documents?: Document[];
}

export interface IPanInfo {
  pan_number: string;
  documents?: Document[];
}

export interface IAddressInfo {
  office_address: string;
  city: string;
  state?: string;
  country?: string;
  pincode: string;
}

export interface IOpeningBalance {
  balance: number;
  currency: string;
}

export interface IDocument {
  doc_type: string;
  file_name: string;
  doc_name: string;
  _id?: string;
}

export interface IContactMember {
  full_name: string;
  contact_number: string;
  email: string;
  _id?: string;
}

export interface IBankDetail {
  bank_name: string;
  acc_name: string;
  acc_number: number;
  ifsc: string;
  _id?: string;
}

interface IVenderState {
  loading: any;
  error: string | null;
  allVendorList: ILedgerData[];
  vendorData: ILedgerData | null;
  isUploadCollapse: boolean;
  contactPerson: IContactMember[];
  bankDetails: IBankDetail[];
  gstDocs: any[];
  panDocs: any[];
  otherDocs: any[];
  isFiledVisible: IIsFiledShow;
}

const initialState: IVenderState = {
  loading: false,
  error: null,
  allVendorList: [],
  vendorData: null,
  isUploadCollapse: false,
  contactPerson: [],
  bankDetails: [],
  gstDocs: [],
  panDocs: [],
  otherDocs: [],
  isFiledVisible: {
    alias: false,
    tds: false,
    address: false,
    paymentTerms: false,
  },
};

const vendorSlice = createSlice({
  name: "Vendor",
  initialState,
  reducers: {
    setContactPerson: (state, action: PayloadAction<IContactMember[]>) => {
      state.contactPerson = action.payload;
    },
    resetContactPerson: (state) => {
      state.contactPerson = [];
    },
    setIsFiledVisible: (
      state,
      action: PayloadAction<{ fieldName: keyof typeof initialState.isFiledVisible; isVisible: boolean }>
    ) => {
      const { fieldName, isVisible } = action.payload;
      state.isFiledVisible[fieldName] = isVisible;
    },
    setBankDetails: (state, action: PayloadAction<IBankDetail[]>) => {
      state.bankDetails = action.payload;
    },
    setGstDocs: (state, action: PayloadAction<any[]>) => {
      state.gstDocs = action.payload;
    },
    setPanDocs: (state, action: PayloadAction<any[]>) => {
      state.panDocs = action.payload;
    },
    setOtherDocs: (state, action: PayloadAction<any[]>) => {
      state.otherDocs = action.payload;
    },
    resetAllFormState: (state) => {
      state.contactPerson = [];
      state.bankDetails = [];
      state.gstDocs = [];
      state.panDocs = [];
      state.otherDocs = [];
      state.vendorData = null;
      state.isUploadCollapse = false;
      state.isFiledVisible = {
        alias: false,
        tds: false,
        address: false,
        paymentTerms: false,
      };
    },
  },
  extraReducers: (builder) => {
    // create vendor list
    builder.addCase(addVendor.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addVendor.fulfilled, (state, action) => {
      state.loading = false;
      state.allVendorList.push(action.payload);
    });
    builder.addCase(addVendor.rejected, (state, action) => {
      state.loading = false;
      const errData: any = action.payload;
      state.error = errData.message as string;
    });
    builder.addCase(fetchVendorList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.loading = false;
      state.allVendorList = action.payload;
    });
    builder.addCase(fetchVendorList.rejected, (state, action) => {
      state.loading = false;
      state.allVendorList = [];
    });
    builder.addCase(getVendorDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.vendorData = action.payload;
    });

    builder.addCase(updateVendorList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateVendorList.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateVendorList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchGstDetails.fulfilled, (state, action) => {
      const { ledgerName, gstNumber, address } = action.payload;

      if (!state.vendorData) {
        state.vendorData = {
          gst: { gst_treatment: "", gst_number: "" },
          pan: { pan_number: "" },
          address: { office_address: "", city: "", pincode: "" },
          opening_balance: { balance: 0, currency: "" },
          account_type: "",
          account_name: "",
          source_of_supply: "",
        };
      }

      const { buildingNumber, buildingName, street, city, pincode, location } = address || {};

      state.vendorData.account_name = ledgerName || "";
      state.vendorData.gst.gst_number = gstNumber || "";

      if (!state.vendorData.address) {
        state.vendorData.address = { office_address: "", city: "", pincode: "" };
      }

      state.vendorData.address.office_address = `${buildingNumber || ""} ${buildingName || ""}, ${street || ""}`;
      state.vendorData.address.city = city || location || "";
      state.vendorData.address.pincode = pincode || "";

      if (address) {
        state.isFiledVisible["address"] = true;
      }
    });
  },
});

export const {
  setContactPerson,
  resetContactPerson,
  resetAllFormState,
  setIsFiledVisible,
  setBankDetails,
  setGstDocs,
  setPanDocs,
  setOtherDocs,
} = vendorSlice.actions;
export default vendorSlice.reducer;
