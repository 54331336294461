import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Flex, Form, Row, Typography } from "antd";
import { downloadDocument } from "../../../utils/AWSService";
import { AppDispatch, RootState } from "../../../store";
import { setPanCardImage } from "../../../slices/contactSlice";
import { DownloadOutlined } from "@ant-design/icons";
import uploadSvg from "../../../assets/upload-svg.svg";
import FileUpload from "../../../components/FileUpload";
import { InputBox } from "../../../components/FormInput";
import FormCss from "./form.module.scss";
import { FiUpload } from "react-icons/fi";
import { capitalizeFirstLetterOfWord } from "../../../utils/commonFunctions";
const _ = require("lodash");

const { Title } = Typography;
interface PancardDocPropsInterface {
  form: any;
  formId: any;
}
const PancardDoc = ({ form, formId }: PancardDocPropsInterface) => {
  const dispatch = useDispatch<AppDispatch>();
  const { panCardImage } = useSelector((state: RootState) => state.contact);
  const { user } = useSelector((state: RootState) => state.user);
  // const [panCard, setPanCard] = useState<any[]>([]);
  const filePlaceholder = (
    <div className="flex flex-col items-center">
      <FiUpload size={40} color="#99A1BB" />
      <div>
        <p style={{ color: "#30bfee", fontSize: "14px" }}>Drag an Image here or click to upload (PNG or JPEG only)</p>
        <span style={{ color: "#99A1BB", fontSize: "14px" }}>
          Upload an image in either a PNG or JPEG format. Maximum size: 2MB.
        </span>
      </div>
    </div>
  );

  const handleRemoveImage = () => {
    dispatch(setPanCardImage({ formId, images: {} }));
  };

  const fileDownload = (data: any, filename: string) => {
    if (_.has(data, "originFileObj")) {
      const link = document.createElement("a");
      link.href = data.thumbUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (_.has(data, "file_name")) {
      downloadDocument(`passenger_doc/${user?.tenant_id}/${formId}/${data.file_name}`, data.doc_type, filename);
    }
  };

  return (
    <>
      <div className="pb-5 p-5">
        {/* <Title level={4} className={`${FormCss.titleLevel4}`}>
          PAN Card
        </Title> */}
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <Form.Item className="passport-dropbox mb-1" name={[formId, "panCard"]}>
              <FileUpload
                onRemove={() => {
                  handleRemoveImage();
                  // form.resetFields([formId, "panCard"]);
                }}
                required
                placeholder={filePlaceholder}
                setFile={(val: any) => {
                  // setPanCard(val);
                  dispatch(setPanCardImage({ formId, images: val }));
                }}
                // fileData={panCard}
                fileData={panCardImage[formId]}
              />
              {_.get(panCardImage, `[${formId}][0]`) && (
                <Flex justify="end" style={{ marginTop: 10 }}>
                  <Button type="text" onClick={() => fileDownload(_.get(panCardImage, `[${formId}][0]`), "Pan_card")}>
                    Download <DownloadOutlined />
                  </Button>
                </Flex>
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <InputBox label="Name" onBlur={(e) => {
                form.setFieldValue([formId, "pancard_name"], capitalizeFirstLetterOfWord(e.target.value));
              }} className="mb-0" placeholder="Enter full name" name={[formId, "pancard_name"]} />
          </Col>
          <Col span={12}>
            <InputBox
              label="Pan Number"
              className="mb-0"
              placeholder="Enter number"
              name={[formId, "pancard_number"]}
              onChange={(e:any) => form.setFieldValue([formId, "pancard_number"], e.target.value.toUpperCase())}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default PancardDoc;
