import React from "react";
import { Route, Routes } from "react-router-dom";
import Item from "./Item";

const Account = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<h2>Account Overview</h2>} />
        <Route path="item" element={<Item />} />
      </Routes>
    </>
  );
};

export default Account;
