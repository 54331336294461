import React, { useEffect, useState } from "react";
import { Button, Card, Col, Flex, Input, Popover, Row, Typography } from "antd";
import FormStyleCss from "./form.module.scss";
import { useDispatch } from "react-redux";
import { setBankDetails } from "../../../slices/vendorSlice";
import { useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import CloseIconWithConfirmation from "../../../components/FormInput/CloseIconWithConfirmation";

const BankPopover: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { bankDetails } = useSelector((state: RootState) => state.vendor);
  const [banks, setBanks] = useState<any>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    if (bankDetails?.length) {
      setBanks([...bankDetails]);
    } else {
      setBanks([{ acc_name: "", bank_name: "", acc_number: "", ifsc: "" }]);
    }
  }, [bankDetails]);

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      return;
    }
    setIsPopoverOpen(newOpen);
  };

  const addBank = () => {
    setBanks([...banks, { acc_name: "", bank_name: "", acc_number: "", ifsc: "" }]);
  };

  const removeBank = (index: number) => {
    if (banks?.length <= 1 && index === 0) {
      setBanks([{ acc_name: "", bank_name: "", acc_number: "", ifsc: "" }]);
      handleSave();
      return;
    }
    setBanks(banks.filter((_: any, i: any) => i !== index));
  };

  const updateBankDetails = (index: number, key: "acc_name" | "bank_name" | "acc_number" | "ifsc", value: string) => {
    setBanks((prev: any) => {
      const updated = [...prev];
      updated[index] = { ...updated[index], [key]: value };
      return updated;
    });
  };

  const handleSave = () => {
    const validBanks = banks.filter(
      (bank: any) => bank.acc_name.trim() || bank.bank_name.trim() || bank.acc_number.trim() || bank.ifsc.trim()
    );

    if (validBanks.length === 0) {
      return;
    }
    dispatch(setBankDetails(validBanks));
    closePopover();
  };

  const handleRemoveBank = (bankId: string, index: number) => {
    let updatedBanks;

    if (bankId) {
      updatedBanks = banks.filter((person: any) => person.id !== bankId);
    } else if (index !== undefined) {
      updatedBanks = banks.filter((_: any, i: number) => i !== index);
    }
    setBanks(updatedBanks);
    dispatch(setBankDetails(updatedBanks));
  };

  const handleEditBank = () => {
    setBanks(bankDetails);
    setIsPopoverOpen(true);
  };

  const content = (
    <div style={{ display: "flex", flexDirection: "column", rowGap: 6 }}>
      {banks?.map((bank: any, index: number) => (
        <div>
          <Flex justify="space-between" align="center" className="header">
            <Typography style={{ color: "black" }}>Bank #{index + 1}</Typography>
            <CloseIconWithConfirmation
              message="Are you want to delete?"
              onConfirm={() => removeBank(index)}
              iconProps={{ size: 14, style: { cursor: "pointer" } }}
            />
          </Flex>
          <Row gutter={[4, 6]} align={"middle"}>
            <Col span={7}>
              <Typography className="title">Bank Name</Typography>
            </Col>
            <Col span={15}>
              <Input
                placeholder="Enter Bank Name"
                value={bank?.bank_name || ""}
                onChange={(e) => updateBankDetails(index, "bank_name", e.target.value)}
              />
            </Col>
            <Col span={7}>
              <Typography className="title">A/c Name</Typography>
            </Col>
            <Col span={15}>
              <Input
                placeholder="Enter A/c Name"
                value={bank?.acc_name || ""}
                onChange={(e) => updateBankDetails(index, "acc_name", e.target.value)}
              />
            </Col>
            <Col span={7}>
              <Typography className="title">A/c Number</Typography>
            </Col>
            <Col span={15}>
              <Input
                placeholder="Enter A/c Number"
                value={bank.acc_number || ""}
                onChange={(e) => updateBankDetails(index, "acc_number", e.target.value)}
              />
            </Col>
            <Col span={7}>
              <Typography className="title">IFSC</Typography>
            </Col>
            <Col span={15}>
              <Input
                placeholder="Enter IFSC"
                value={bank.ifsc || ""}
                onChange={(e) => updateBankDetails(index, "ifsc", e.target.value)}
              />
            </Col>
          </Row>
        </div>
      ))}
      <Flex justify="space-between" align="center">
        <Button onClick={addBank} type="link" disabled={banks?.length >= 3} className="addMorebtn">
          Add More
        </Button>
        <Button onClick={handleSave} className="savebtn">
          Save
        </Button>
      </Flex>
    </div>
  );

  return (
    <>
      <Popover
        content={content}
        trigger="click"
        onOpenChange={handleOpenChange}
        open={isPopoverOpen}
        placement={"right"}
        overlayStyle={{
          width: "300px",
          maxHeight: "60%",
          boxShadow: "0px 0px 8px #b9b9b9",
          borderRadius: "4px",
        }}
        overlayClassName={FormStyleCss.ContactPersonpopover}
      >
        {!bankDetails?.length && (
          <Button style={{ boxShadow: "none" }} type="link">
            Add Bank Detail
          </Button>
        )}
      </Popover>

      <Flex gap={10} className={FormStyleCss.contactPersonCard}>
        {bankDetails?.map((bank: any, index: number) => {
          return (
            <Card>
              <Flex gap={20}>
                <span>
                  <Typography className="name">{bank.bank_name}</Typography>
                  <Typography className="name">{bank.acc_name}</Typography>
                  <Typography className="otherDetail">{bank.acc_number}</Typography>
                  <Typography className="otherDetail">{bank.ifsc}</Typography>
                </span>
                <CloseIconWithConfirmation
                  message="Are you want to delete?"
                  onConfirm={() => handleRemoveBank(bank?.id, index)}
                  iconProps={{ size: 14, color: "#ff4d4f", style: { cursor: "pointer", marginTop: "4px" } }}
                />
              </Flex>
            </Card>
          );
        })}
        {/* <CiEdit size={18} color={'#494949'} style={{marginTop:'6px'}}/> */}
        {!!bankDetails?.length && (
          <Typography className="edit" onClick={handleEditBank} style={{ cursor: "pointer" }}>
            Edit
          </Typography>
        )}
      </Flex>
    </>
  );
};

export default BankPopover;
