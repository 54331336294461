import { useEffect, useRef, useState } from "react";
import { Alert, Button, Divider, Flex, Form, message, Typography } from "antd";
import { AppDispatch, RootState } from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { setVendorDrawerOpen } from "../../slices/layoutSlice";
import { DRAWER_TYPE } from "../../utils/const";
import DrawerCustom from "../../components/Drawer";
import VendorForm from "./VendorForm";
import VendorStyleCss from "./style.module.scss";
import {
  addVendor,
  fetchVendorList,
  getVendorDetails,
  resetAllFormState,
  updateVendorList,
  setContactPerson,
  setBankDetails,
} from "../../slices/vendorSlice";
import _ from "lodash";
import { addItemInList, closeItem } from "../../slices/minimizeSlice";
import commonStyle from "../../css/commonStyle.module.scss";

const { Text } = Typography;

interface ICreateContact {
  vendorId?: string;
}

interface User {
  name: string;
  contactNumber: string;
}

function CreateVendor({ vendorId }: ICreateContact) {
  const dispatch = useDispatch<AppDispatch>();
  const formRef = useRef<any>(null);
  const { vendorDrawerOpen } = useSelector((state: RootState) => state.layout);
  const { contactPerson, vendorData, loading, bankDetails, gstDocs, panDocs, otherDocs } = useSelector(
    (state: RootState) => state.vendor
  );
  const { openItemList } = useSelector((state: RootState) => state.minimize);
  const [existingList, setExistingList] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const currentOpen = openItemList[openItemList.length - 1];

  useEffect(() => {
    if (_.isEqual(vendorId, _.get(currentOpen, "_id")) && vendorId) {
      dispatch(getVendorDetails(vendorId!));
    } else {
      form.resetFields();
    }
  }, [vendorId, currentOpen?._id]);

  useEffect(() => {
    setIsModalOpen(vendorDrawerOpen === DRAWER_TYPE.VENDOR_DRAWER || Boolean(vendorId));
  }, [vendorDrawerOpen, vendorId]);

  useEffect(() => {
    if (vendorData) populateFormFields(vendorData);
  }, [vendorData]);

  const drawerClose = () => {
    setIsModalOpen(false);
    formRef.current.resetFields();
    if (vendorId) {
      setTimeout(() => {
        dispatch(closeItem(vendorId));
        dispatch(resetAllFormState());
        setIsSaved(false);
      }, 300);
    } else {
      dispatch(resetAllFormState());
      form.resetFields();
      setExistingList([]);
      dispatch(setVendorDrawerOpen(null));
    }
  };

  const dataSaved = () => {
    setIsSaved(true);
    setTimeout(() => setIsSaved(false), 2000);
  };

  const handleMinimize = () => {
    if (vendorId) {
      dispatch(
        addItemInList({
          name: form.getFieldValue("contact_name"),
          _id: vendorId,
          type: "VENDOR",
        })
      );
      drawerClose();
    }
  };

  // Submit Form
  const handleFinish = async (values: any) => {
    const formData = new FormData();
    const ledgerData = createLedgerData(values);

    appendDocumentsToFormData(formData, "gst", gstDocs);
    appendDocumentsToFormData(formData, "pan", panDocs);
    appendDocumentsToFormData(formData, "other", otherDocs);

    formData.append("ledgerData", JSON.stringify(ledgerData));

    if (vendorId && vendorData) {
      const response: any = await dispatch(updateVendorList({ id: vendorId, updatedData: formData }));
      if (response) {
        dataSaved();
        dispatch(fetchVendorList());
      }
    } else {
      const response: any = await dispatch(addVendor(formData));
      if (!response.payload) {
        drawerClose();
        dispatch(fetchVendorList());
      } else {
        message.error(response.payload?.error?.message);
        setExistingList(response.payload?.error?.data);
      }
    }
  };

  const handleFinishFailed = (errorInfo: any) => {
    if (errorInfo && errorInfo?.errorFields?.length > 0) {
      message.error("Please enter data in all required fields.");
    }
  };

  // Helper: Create Ledger Data
  const createLedgerData = (values: any) => {
    return {
      account_type: values.user_type || "VENDOR",
      gst: {
        gst_treatment: values.gst_treatment,
        gst_number: values.gst_number,
      },
      pan: {
        pan_number: values.pan_number,
      },
      account_name: values.account_name || "",
      alias: values.nick_name || "",
      tds: values.tds || "",
      address: _.pick(values, ["office_address", "city", "state", "country", "pincode"]),
      source_of_supply: values.source_of_supply || "",
      opening_balance: _.pick(values, ["currency", "balance", "balance_type"]),
      contact_members: contactPerson?.map((contact: any) => ({
        id: contact.id,
        full_name: contact.name,
        contact_number: contact.mobile,
        email: contact.email,
      })),
      bank_details: bankDetails?.map((bank: any) => ({
        bank_name: bank.bank_name,
        acc_name: bank.acc_name,
        acc_number: bank.acc_number,
        ifsc: bank.ifsc,
      })),
      payment_terms: values.payment_terms,
    };
  };

  // Helper: Append Documents to FormData
  const appendDocumentsToFormData = (formData: FormData, key: string, docs: any[]) => {
    docs?.forEach((doc, index) => formData.append(`${key}_${index}`, doc.originFileObj, doc.name));
  };

  // Helper: Populate form fields
  const populateFormFields = (vendor: any) => {
    const { gst, pan, address, opening_balance, contact_members, bank_details, _id } = vendor;

    if (_id) {
      form.setFieldsValue({
        user_type: vendor.account_type || "VENDOR",
        gst_treatment: gst?.gst_treatment,
        gst_number: gst?.gst_number,
        account_name: vendor.account_name,
        nick_name: vendor.alias,
        pan_number: pan?.pan_number,
        tds: vendor.tds,
        office_address: address?.office_address,
        city: address?.city?.trim(),
        pincode: address?.pincode,
        source_of_supply: vendor.source_of_supply,
        currency: opening_balance?.currency,
        balance: opening_balance?.balance,
        balance_type: opening_balance?.balance_type,
        payment_terms: vendor.payment_terms,
      });
    } else {
      form.setFieldsValue({
        gst_number: gst?.gst_number,
        account_name: vendor.account_name,
        office_address: address?.office_address,
        city: address?.city?.trim(),
        pincode: address?.pincode,
      });
    }

    if (contact_members) {
      const contactList = contact_members?.map((member: any) => ({
        id: member?._id,
        name: member?.full_name,
        email: member?.email,
        mobile: member?.contact_number,
      }));
      dispatch(setContactPerson(contactList));
    }

    if (bank_details) {
      const banks = bank_details?.map((bank: any) => ({
        bank_name: bank?.bank_name,
        acc_name: bank?.acc_name,
        acc_number: bank?.acc_number,
        ifsc: bank?.ifsc,
        id: bank?._id,
      }));
      dispatch(setBankDetails(banks));
    }
  };

  return (
    <>
      <DrawerCustom
        handleMinimize={handleMinimize}
        drawerCloseHandle={drawerClose}
        open={isModalOpen}
        width={"40%"}
        closeText="Ledger"
        showSavedStatus={vendorId ? true : false}
        isLoading={loading}
        isDataUpdated={isSaved}
        showMinimize={vendorId ? true : false}
        zIndex={1000}
        wrapClassName={VendorStyleCss.vendorDrawer}
      >
        <div>
          <div style={{ padding: "24px" }}>
            <Text className="drawer-title">{vendorId ? "Ledger Detail" : "New Ledger"}</Text>
          </div>
        </div>

        {existingList?.length > 0 && (
          <Alert
            message={`This User ${existingList?.map((usr: User) => ` ${usr.name}`)} Vendor Number Alreasdy Exist`}
            type="error"
            style={{ marginBottom: 12 }}
          />
        )}

        <Divider
          style={{
            borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
            margin: 0,
          }}
        />

        <Form
          form={form}
          name="dynamic_form"
          autoComplete="off"
          layout="vertical"
          onFinish={handleFinish}
          onFinishFailed={handleFinishFailed}
          scrollToFirstError
        >
          <div className="vendorForm">
            <VendorForm form={form} ref={formRef} />
            <Divider
              style={{
                borderBlockStart: "1px solid rgba(219, 223, 233, 1)",
                margin: 0,
              }}
            />
            <Flex gap={15} justify="start" style={{ marginTop: "15px" }} className="pl-6">
              <Button className={commonStyle.primaryBtn} htmlType="submit" loading={loading}>
                Save
              </Button>
              <Button className={commonStyle.cancelBtn} onClick={drawerClose}>
                Cancel
              </Button>
            </Flex>
          </div>
        </Form>
      </DrawerCustom>
    </>
  );
}

export default CreateVendor;
